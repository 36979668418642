<template>
  <div class="main">
    <heads :more="showMore" :msg="title"></heads>
    <div class="lists">
      <van-list v-show="showList" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="box" v-for="item in lists">
          <div class="left">
            <p class="reason">{{ item.reason }}</p>
            <p class="time">{{ item.create_time }}</p>
          </div>
          <div class="right">
            <span v-if="item.score < 0" class="number">{{ item.score }}</span>
            <span v-else-if="item.score > 0" class="number">+{{ item.score }}</span>
            <span v-else class="number">{{ item.score }}</span>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { myInteg } from '@/api/regInfo'
export default {
  components: {
    heads
  },
  data () {
    return {
      showMore: false,
      title: this.$route.query.name ? this.$route.query.name : '记录',
      lists: [],
      showList: true,
      loading: false,
      finished: false,
      count: 0,
      page: 1,
      limit: 10
    }
  },
  created () {
    this.unitForGetDatas()
  },
  mounted () {

  },
  methods: {
    //记录
    unitForGetDatas () {
      this.page = 1
      this.getDataList()
    },
    getDataList () {
      const p = {
        page: this.page,
        limit: this.limit
      }
      this.loading = true
      myInteg(p).then(res => {
        console.log(res);
        const errNo = res.errNo
        this.loading = false
        if (errNo === 0) {
          const result = res.result ? res.result : {}
          const list = result.list ? result.list : []
          this.lists = [...this.lists, ...list]
          this.count = result.count ? result.count : 0
          if (this.lists.length < this.count) {
            this.page++
          }
          else {
            this.finished = true
          }
        }
      })
    },
    // 加载更多
    onLoad () {
      this.getDataList()
    }
  }
}
</script>

<style lang="scss" scoped>
.lists {
  width: calc(100% -60px);
  padding: 0 30px;
  .box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5;
    .left {
      width: calc(100% - 160px);
      .reason {
        font-family: PingFangSC-Regular;
        font-size: 24px;
        color: #333333;
      }
      .time {
        margin-top: 4px;
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #b0b0b0;
      }
    }
    .right {
      width: 160px;
      .number {
        display: flex;
        justify-content: right;
        font-family: PingFangSC-Regular;
        font-size: 30px;
        color: #333333;
      }
    }
  }
}
</style>
